import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import "froala-editor/js/plugins.pkgd.min.js";
import { uploadMedia } from "../../apiCall";
import { Loader, Modal } from "semantic-ui-react";
// import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
// import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import Froalaeditor from "froala-editor";
import Froala from "react-froala-wysiwyg";

const FroalaEditor = (props) => {
  const [fileCount, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  let imageFiles = [];
  const editorRef = useRef(null);
  const [defaultContent, setDefaultContent] = useState(
    props.content?.length > 0 ? props.content : ""
  );
  
  useEffect(() => {
    if(props.content === ""){
         setDefaultContent("");
    }else{
         setDefaultContent(props.content);
    }
  }, [props.content]);
  const config = {
    key: "4NC5fB4D4C3E3C2C4A3D-13TMIBDIa2NTMNZFFPFZe2a1Id1f1I1fA8D6C4B4G3H3G2A18A15B6==",
    wordPasteModal: false,
    wordPasteKeepFormatting: false,
    attribution: false,
    fileUpload: false,
    heightMin: 200,
    heightMax: 300,
    tabSpaces: 5,
    //Image parameters
    imageUpload: true,
    quickInsertEnabled: false,
    imageDefaultAlign: "left",
    linkText: false,
    linkAlwaysBlank: true,
    imageDefaultDisplay: "block",
    imageInsertButtons: ["imageBack", "|", "imageUpload", "imageByURL"],
    // Set max image size to 100MB.
    imageMaxSize: 100 * 1024 * 1024,
    // Allow to upload PNG and JPG.
    imageAllowedTypes: ["jpeg", "jpg", "png"],
    // Video Parameteres
    videoAllowedTypes: ["mp4", "webm", "ogg", "mov"],
    videoMaxSize: 1024 * 1024 * 100,
    videoUpload: true,
    videoInsertButtons: ["videoBack", "|", "videoByURL"],
    events: {
      "image.inserted": async function (img, response) {
        let editor = this;
        imageFiles.push(img);
        setCount(fileCount + 1);
 
       
          setLoading(true);
          let src = img.attr("src");
          let srcSplit = src.split("/");
     
          await fetch(src)
            .then((res) => res.blob())
            .then(async (blob) => {
              const currentImage = img;
              const file = new File(
                [blob],
                srcSplit[srcSplit.length - 1],
                blob
              );
 
              const data = new FormData();
              data.append("media", file);
              await uploadMedia(data)
                .then(async (res) => {
                  currentImage.attr("src", res.data.data.url);
                  img = currentImage;
 
                  var htmlContent = editor.html.get();
                  var updatedContent = htmlContent.replace(
                    src,
                    res.data.data.url
                  );
                  editor.html.set(updatedContent);
                  props.getContent(updatedContent);
                })
                .catch((err) => {
                  console.log("err", img, err);
                });
            });
       
        setLoading(false);
      },
    },
    imageEditButtons: [
      "imageCaption",
      "imageReplace",
      "imageAlign",
      "imageRemove",
      "|",
      "imageLink",
      "linkOpen",
      "linkEdit",
      "linkRemove",
      "-",
      "imageDisplay",
      "imageStyle",
      "imageAlt",
    ],
    toolbarButtons: {
      moreText: {
        buttons: [
          "bold",
          "italic",
          "underline",
          "strikeThrough",
          "subscript",
          "superscript",
          "fontFamily",
          "fontSize",
          "textColor",
          "backgroundColor",
          "inlineClass",
          "inlineStyle",
          "clearFormatting",
         
        ],
      },

      moreParagraph: {
        buttons: [
          "alignLeft",
          "alignCenter",
          "alignRight",
          "alignJustify",

          //   "formatOLSimple",
          "formatOL",
          "formatUL",
          "paragraphFormat",
          "lineHeight",
          "outdent",
          "indent",
          "quote",
          "clear"
        ],
      },

      moreRich: {
        buttons: [
          "insertTable",
          "insertLink",
          "insertImage",
          "insertVideo",

          "emoticons",
          "fontAwesome",
          "specialCharacters",
          "embedly",
          "insertHR",
        ],
      },
      moreMisc: {
        buttons: [
          "undo",
          "redo",
          "fullscreen",
          "spellChecker",
          "selectAll",
          "help",
        ],
        align: "right",
        buttonsVisible: 3,
      },
    },
  };

  const onModelChange = (model) => {

    let edData = model;
    let elem = document.createElement("div");
    elem.innerHTML = edData;
    setDefaultContent(edData);
    props.getContent(edData);
  };

  return (
    <div className="EditorComponent">
      <Modal open={loading}>
        <Loader active>Image is processing...</Loader>
      </Modal>

      <Froala
        ref={editorRef}
        model={defaultContent}
        onModelChange={(e) => onModelChange(e)}
        // tag="textarea"
        config={config}
      ></Froala>
    </div>
  );
};

export default FroalaEditor;
