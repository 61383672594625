import axios from "axios";

const SIGNUP = `${process.env.REACT_APP_BACKEND_URL}/users`;
const LOG_DATA = `${process.env.REACT_APP_BACKEND_URL}/dummy`;

const FIREBASE_CALL = `${process.env.REACT_APP_BACKEND_URL}/users/firebaseToken`;
const DOCUMENT_API = `${process.env.REACT_APP_BACKEND_URL}/documents`;
const STEPS_API = `/steps`;
const STEP_API = "/step/";
const GET_NOTIFICATIONS = `${process.env.REACT_APP_BACKEND_URL}/notifications`;
const READ_ALL_NOTIFICATIONS = `${process.env.REACT_APP_BACKEND_URL}/notifications/updateunreads`;
const UNREAD_NOTIFICATIONS = `${process.env.REACT_APP_BACKEND_URL}/notifications/unread`;
const COMMENTS_API = `/comments`;
const FEATURE_FLAG = `${process.env.REACT_APP_BACKEND_URL}/feature-flags`;
const DRAFT_WITH_AI=`https://aidraftapi.xtdocc-dev.lexxtechnologies.com/generation`;
export function unreadNotification() {
  return axios.get(UNREAD_NOTIFICATIONS);
}

export function draftWithAi(data) {
  return axios.post(DRAFT_WITH_AI , data);
}
// export async function getFeatureFlag(data){
//   return axios.get(FEATURE_FLAG);
// }
export function markNotificationRead(url) {
  return axios.post(GET_NOTIFICATIONS + url);
}

export function authSignup(data) {
  return axios.post(SIGNUP, data);
}

export function getNotifications() {
  return axios.get(GET_NOTIFICATIONS);
}

export function logData(data) {
  return axios.post(LOG_DATA, data);
}

export function sendFirebaseToken(data) {
  return axios.post(FIREBASE_CALL, data);
}

export function saveNewDocument(document) {
  return axios.put(DOCUMENT_API, document);
}

export function getAllDocument() {
  return axios.get(DOCUMENT_API);
}

export function deleteDocument(id) {
  return axios.delete(DOCUMENT_API + "/" + id);
}

export function saveNewStep(data, id) {
  return axios.put(DOCUMENT_API + "/" + id + STEPS_API, data);
}

export function deleteSteps(documentId, stepId) {
  return axios.delete(
    DOCUMENT_API + "/" + documentId + STEPS_API + "/" + stepId
  );
}

export function getDocumentById(documentId) {
  return axios.get(DOCUMENT_API + "/" + documentId);
}

export function getStepsByDocumentId(documentId) {
  return axios.get(DOCUMENT_API + "/" + documentId + STEPS_API);
}
export function getStepsByStepId(stepId) {
  return axios.get(DOCUMENT_API + STEP_API + stepId);
}

export function saveEditedStep(documentId, data, stepId) {
  return axios.patch(
    DOCUMENT_API + "/" + documentId + STEPS_API + "/" + stepId,
    data
  );
}

export function updateDocument(data, documentId) {
  return axios.patch(DOCUMENT_API + "/" + documentId, data);
}

export function getFeedbackdocument(documentId) {
  return axios.get(DOCUMENT_API + "/" + documentId + COMMENTS_API);
}

export function updateFeedbackStatus(documentId, stepId, feedbackId, data) {
  return axios.patch(
    DOCUMENT_API +
      "/" +
      documentId +
      "/steps/" +
      stepId +
      "/comments/" +
      feedbackId,
    data
  );
}

export function getStepFeedbackData(documentId, stepId) {
  return axios.get(DOCUMENT_API + "/" + documentId + "/steps/" + stepId);
}

export function readAllNotifications(value) {
  return axios.post(READ_ALL_NOTIFICATIONS);
}

export function uploadMedia(file) {
  return axios.post(DOCUMENT_API + "/files", file);
}
