// import React, { useState } from 'react';
import React, { useEffect, useState } from "react";
import { Button, Card, Image, Modal } from "semantic-ui-react";
import Technician from "../../assets/images/technician.png";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  setSelectedTemplate,
  setProcedureDetails,
  resetProcedureDetails,
  resetStepDetails,
  saveIsReviewer,
  setNewDocument,
} from "./../../redux/actions";

/* This component is for Dashboard buttons - Troubleshooting, Lookup and Help from Technician */
const LexxDashboardCard = (props) => {
  const templateOptions = [
    {
      key: "Troubleshooting Guide",
      value: "Troubleshooting Guide",
    },
    {
      key: "Lorem Ipsum",
      value: "Lorem Ipsum",
    },
  ];
  const [popupVisible, setPopupVisible] = useState(false);
  const [template, setTemplate] = useState(templateOptions[0].key);

  const handlePopUpModal = () => {
    setPopupVisible(!popupVisible);
  };
  const lookUpButtonFunc = () => {
    const redirectToExternalResource = (url) => window.open(url, "_blank");
    redirectToExternalResource(`${process.env.REACT_APP_VANOORD_URL}`);
  };
  useEffect(() => {
    props.resetProcedureDetails();

    props.resetStepDetails();
    props.setNewDocument(false);
  }, []);

  const onValueChanged = (event) => {
    //event.preventDefault();
    setTemplate(event.target.value);
  };

  const handleTemplateSelection = (e) => {
    e.preventDefault();
    props.setNewDocument(true);
    props.setSelectedTemplate(template);
    props.history.push("/CreateInstructionsForm");
  };

  return (
    <>
      <Card className="dashboard-card">
        <div className="dashboard-card-content">
          <div className="dashboard-card-image">
            <Image src={Technician} className="dashboard-card-image-holder" />
          </div>
          <div className="dashboard-card-messages">
            <h3>Hi, LexX here!</h3>
            <p className="label">What would you like to do?</p>
          </div>
          <div className="dashboard-card-buttons">
            <Button
              className="secondaryButton"
              onClick={() => {
                lookUpButtonFunc();
              }}
            >
              Look up Information
            </Button>
            <Button
              className="primaryButton"
              onClick={() => {
                handlePopUpModal();
              }}
             
            >
              Create a New Document
            </Button>
          </div>
        </div>
      </Card>
      {popupVisible && (
        <Modal
          className="modal-upload"
          open={popupVisible}
          onClose={() => setPopupVisible(false)}
        >
          <div className="modal-heading">
            <h3>Choose the Template</h3>
            <button onClick={() => setPopupVisible(false)}>X</button>
          </div>
          <div className="modal-action">
            <Modal.Actions className="modal-radio">
              <div className="modal-radio-group ">
                <div>
                  <input
                    className="modal-radio-custom "
                    type="radio"
                    name=""
                    value={templateOptions[0].key}
                    checked={template === templateOptions[0].key}
                    onChange={(e) => onValueChanged(e)}
                  />
                  <label className="modal-radio-custom-label">
                    {templateOptions[0].value}
                  </label>
                </div>
                <p
                  style={{
                    fontSize: "11px",
                    fontStyle: "italic",
                    fontWeight: "400px",
                    color: "GrayText",
                    marginTop: "15px",
                    marginLeft: "26px",
                    marginRight: "10px",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at dolor lobortis, 
                  pharetra felis in, scelerisque risus. Praesent iaculis quam sed ligula consequat, 
                  porttitor consectetur eros vestibulum.In hac habitasse platea dictumst. Maecenas 
                  id varius mauris. Suspendisse lacinia risus orci
                </p>
              </div>
              <div className="modal-radio-group ">
                <div>
                  <input
                    className="modal-radio-custom "
                    type="radio"
                    name=""
                    value={templateOptions[1].key}
                    checked={template === templateOptions[1].key}
                    onChange={(e) => onValueChanged(e)}
                  />
                  <label className="modal-radio-custom-label">
                    {templateOptions[1].value}
                  </label>
                </div>
                <p
                  style={{
                    fontSize: "11px",
                    fontStyle: "italic",
                    fontWeight: "400px",
                    color: "GrayText",
                    marginTop: "15px",
                    marginLeft: "26px",
                    marginRight: "10px",
                  }}
                >
                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at dolor lobortis, 
                  pharetra felis in, scelerisque risus. Praesent iaculis quam sed ligula consequat, 
                  porttitor consectetur eros vestibulum.In hac habitasse platea dictumst. Maecenas 
                  id varius mauris. Suspendisse lacinia risus orci
                </p>
              </div>
              <div>
                <Button
                  className="modal-button-option"
                  onClick={(e) => handleTemplateSelection(e)}
                >
                  Choose
                </Button>
              </div>
            </Modal.Actions>
          </div>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    procedureDetails: state.procedureDetails,
    stepDetails: state.stepDetails,
    isReviewer: state.isReviewer,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setSelectedTemplate,
    setProcedureDetails,
    resetProcedureDetails,
    resetStepDetails,
    saveIsReviewer,

    setNewDocument,
  })(LexxDashboardCard)
);
