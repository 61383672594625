import React, { useState } from 'react'
import {Form ,Button} from "semantic-ui-react";
import {setProcedureDetails ,addStepFromApi,aiSolnCreatedAction,saveDaftWithAIForm}  from "../../redux/actions";
import  {draftWithAi} from "../apiCall"
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import BouncingDotsLoader from './Loader';
const DraftWithAI = (props) => {
  const draftwithAIForm =  useSelector((state)=>state.draftwithAIForm);
  const aiSolnCreated = useSelector((state)=>state.aiSolnCreated)
const [loader,setLoader] = useState(false);

const [form, setForm] = useState(draftwithAIForm?.title?draftwithAIForm :{
  title:"",
  notes:"",
  template:"s1kd",
  knowledge_collections:""
});

const EquipmentList = ["Suzlon"]
const knowledge_collectionsList= ["edpr_suzlon_flat_ts"];
const dispatch = useDispatch();
const handleChange = (e) => {
  
  setForm({ ...form, [e.target.name]: e.target.value });
};

const passToDraftWithAIApi = async()=>{
  setLoader(true)
dispatch(saveDaftWithAIForm(form));
form.knowledge_collections =!Array.isArray(form.knowledge_collections) ? [form.knowledge_collections]:form.knowledge_collections;
await draftWithAi(form).then((res)=>{

  const data = {
    faultTitle: res?.data?.Fault_isolation?.fault_Description,
    faultCode: res?.data?.Fault_isolation?.fault_Code,
    faultDescription: res?.data?.Fault_isolation?.reason[0],
    SafetyWarnings: "",
    Symptoms:"",
    Spares: "",
    Hazards:  "",
    SupportEquips:  "", 
  };
  
  dispatch(aiSolnCreatedAction(true))
  dispatch(setProcedureDetails(data));
  const stepFromResponse = Object.values(res?.data?.Fault_isolation?.step_activities);

  let isolationSteps = [];
  
  if (Array?.isArray(stepFromResponse)) {
    
    isolationSteps = stepFromResponse?.map((step) => ({
      //this id is for frontend handling , we passed backend with different id
      stepId: Math.random(),  
      stepTitle: step?.issue,
      stepDescription: step?.action,
    }));
 
  } else if (stepFromResponse) {
    isolationSteps = [{
        //this id is for frontend handling , we passed backend with different id
      stepId: Math.random(),
      stepTitle: stepFromResponse?.issue,
      stepDescription: stepFromResponse?.action,
    }];
  }

  dispatch(addStepFromApi(isolationSteps));
  props.history.push("/CreateInstructionsForm");
});
}

  return (
    <div className="session-summary form-page-wrapper">
        <Form>
    <Form.Field required className="input-wrap">
            <p className="form-label-AI">Describe Your Problem<span style={{color:"red"}}>*</span> </p>
            <input
              name="title"
              placeholder="Describe your problem"
              className="draft-input"
              value={form.title}
              onChange={handleChange}
              maxLength="250"
            />
          </Form.Field>
          <Form.Field required className="input-wrap">
            <p className="form-label-AI">Key Points<span style={{color:"red"}}>*</span> </p>
            <textarea
              name="notes"
              placeholder="Keypoints"
              className="draft-input"
              value={form.notes}
              onChange={handleChange}
              maxLength="250"
            />
          </Form.Field>
          <div className='caregory-ai-div'>
       
          <Form.Field required className="input-wrap">
            <p className="form-label-AI">Equipment/OEM</p>
            <select
              value={EquipmentList[0]}
              name="template"
              placeholder="Click here to select"
              onChange={()=>{}}
              className={
                `${true
                  ? "category-dropdown-AI-color"
                  : "category-dropdown-AI"}
             draft-input `}
            >
              {EquipmentList.map((option) => (
                <>
                  <option key="blankKey" hidden value="">
                    Click here to select the category
                  </option>
                  <option value={option}>{option}</option>
                </>
              ))}
            </select>
            
          </Form.Field>
          <Form.Field required className="input-wrap">
            <p className="form-label-AI">Model<span style={{color:"red"}}>*</span></p>
            <select
              value={form.knowledge_collections}
              name="knowledge_collections"
              placeholder="Click here to select"
              onChange={handleChange}
              className={
                `${true
                  ? "category-dropdown-AI-color"
                  : "category-dropdown-AI"}
             draft-input `}
            >
              {knowledge_collectionsList.map((option) => (
                <>
                  <option key="blankKey" hidden value="">
                    Click here to select the category
                  </option>
                  <option value={option} >{option==="edpr_suzlon_flat_ts"?"Suzlon S88":option}</option>
                </>
              ))}
            </select>
            
          </Form.Field></div>
          </Form>
          <div className="buttons">
          <Button 
          disabled={form.title==="" || form.notes==="" || form.knowledge_collections ==="" } onClick={()=>passToDraftWithAIApi()}
            className="savebtn"
          >
           { aiSolnCreated ? "Regenerate" :"Generate"}
          </Button>
          <Button
            className="cancelbtn"
            onClick={()=>props.history.push("/CreateInstructionsForm")}
          >
            Back 
          </Button>
         
        </div>
        {loader && <BouncingDotsLoader /> }
    </div>
  )
}

export default DraftWithAI
