import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Button,
  Form,
  Modal,
  Popup,

} from "semantic-ui-react";
import AllSteps from "./Container";
import {
  addStep,
  setSelectedTemplate,
  setEnableEditStep,
  setProcedureDetails,
  resetProcedureDetails,
  saveDocumentMedia,
  setPreview,
  resetEditStepIds,
  resetEditStep,
  saveIsReviewer,
  setNewDocument,
  resetDocumentMedia,
  resetFeedback,
  saveDocumentfeedback,
  setEnablePreview,
  setFormEdit,
  passMediaURL,
  setBlankFields,
  showPopupAction,
  mediafiletorender,
  saveEditedStepIds,
  savePrevData,
  setStepFormEdit,
} from "./../../redux/actions";
import { connect, useDispatch } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  saveNewDocument,
  updateDocument,
  saveEditedStep,
  uploadMedia,
} from "../apiCall";
import ProgressBar from "@ramonak/react-progress-bar";
import FroalaEditor from "./FriolaEditor/FroalaEditor";
import regenerate from "../../assets/images/regenerate.png";
import KeycloakUserService from "../login/KeyCloakService";
import CreateInstructionsSteps from "./CreateInstructionsSteps";

const CreateInstructionsForm = (props) => {
  const fileRef = useRef();
  const imageRef = useRef();
const createStepRef = useRef(null);
let _timer;
  const validateProcedureStoreData = () => {
    if (
      props.procedureDetails?.faultTitle?.length > 0 ||
      props.procedureDetails?.faultDescription?.length > 0 ||
      props.procedureDetails?.category?.length > 0
    ) {
      return true;
    }
    return false;
  };
  const [fileToBeRemoved, setFileToBeRemoved] = useState([]);
  const [media, setMedia] = useState("");
  const [editedStepIdsState, setEditedStepIdsState] = useState(
    props.editedStepIds
  );


  const [createStep, setCreateStep] = useState(false);

  const [editedFields, seteditedFields] = useState(
    props?.procedureDetails?.changeLog
      ? props?.procedureDetails?.changeLog
      : {
          faultTitle: "",
          faultDescription: "",
          category: "",
        }
  );
  const [changeLog, setShowChangeLog] = useState(false);
  
  const [form, setForm] = useState(
    validateProcedureStoreData()
      ? props.procedureDetails
      : {
        faultTitle: "",
        faultDescription: "",
        SafetyWarnings: "",
        Symptoms:"",
        Spares: "",
        Hazards:  "",
        SupportEquips:  "", 
        faultCode:  "",
        
      }
  );

  const [formContentChange, setFormContentChange] = useState(false);
  const [description, setDescription] = useState(form.faultDescription);
  //filedetails is used to display the media on UI
  const [fileDetails, setFileDetails] = useState(
    props.docMedia?.fileDetails?.length > 0 ? props.docMedia.fileDetails : []
  );

  //mediaToBeSaved is used to save the media in API
  const [mediaToBeSaved, setMediaToBeSaved] = useState([]);
  const [loading, setLoading] = useState(false);

  const [saveInProgress, setSaveInProgress] = useState(false);
  const [showPendingAlert, setShowPendingAlert] = useState(false);
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [enableDonePopup, setEnableDonePopup] = useState(false);
  const [deleteFileCollection, setDeleteFileCollection] = useState([]);
  const [mediaFilesDownloaded, setMediaFilesDownloaded] = useState(
    props.mediarender ? props.mediarender : []
  );
  const [documentData, setDcoumentData] = useState(
    props.procedureDetails?.documentId?.length > 0
      ? props.procedureDetails
      : null
  );

  const [uploadedMediaURL, setUploadedMedia] = useState();
  const [showToast, setShowToast] = useState(false);
  const defaultStep = {
    stepId: "001",
    stepTitle: "Step Name",
    stepDescription: [
      {
        type: "paragaph",
        children: [{ text: " " }],
      },
    ],
  };

  

  
  useEffect(()=>{
    if(description!=="")
    {
      setForm({...form,["faultDescription"]:description})
      props.setProcedureDetails({...form,["faultDescription"]:description})
    }
    },[description])

  useEffect(() => {
    showToast &&
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
  }, [showToast]);

  useEffect(() => {
    if (
      fileDetails?.length > 0 ||
      mediaFilesDownloaded?.length > 0 ||
      deleteFileCollection?.length > 0
    ) {
      const data = {
        fileDetails: fileDetails,
        media: mediaFilesDownloaded,
        mediaToBeDeleted: deleteFileCollection,
      };
      props.saveDocumentMedia(data);
    }
    if (deleteFileCollection?.length > 0) {
      handleDone(false, false, false);
    }
  }, [fileDetails, mediaFilesDownloaded, deleteFileCollection]);

  const getContent = useCallback((descriptionContent) => {
    setDescription(descriptionContent);
  }, []);

  /* Call this function when there is any change to the form */
  const handleChange = (e) => {
    let inputField = e.target.name;

    setForm({ ...form, [e.target.name]: e.target.value });
    props.setProcedureDetails({ ...form, [e.target.name]: e.target.value })
    seteditedFields({
      ...editedFields,
      [e.target.name]: KeycloakUserService.getUsername(),
    });
    props.setFormEdit(true);
    setFormContentChange(true);
    props.setEnablePreview(true);
  };


  const handleCreateStep = (e) => {
    if(_timer){
      clearTimeout(
        _timer
      )
    }
  _timer=  setTimeout(()=>{

      createStepRef.current.scrollIntoView({behavior: 'smooth'})
    },100)
      setCreateStep(true)
  
  };

  const handlecancelStep = async (e) => {
    e.preventDefault();
   props.history.push("/")
  }

  const handleYes = (publish=true) => {
    setShowPendingAlert(true)
    if(!props.procedureDetails?.documentId)
    {
      saveNewDocumentData(publish);
    }
  else{
    updateDocumentData(publish)
  }
     };
 
  const handleNo = (e,publish=false) => {
    setShowPendingAlert(true)
    e.stopPropagation();
    if(!props.procedureDetails?.documentId)
    {
      saveNewDocumentData(publish);
    }
  else{
    updateDocumentData(publish)
  }
 
  };

  // Save procedure data
  const saveNewDocumentData = async (publish) => {
    let isolationSteps;
    isolationSteps = props.stepDetails.map((step,i) => {
      return {
        isolationStepHeadingElemGroup: {
          title: step.stepTitle,
          warningAndCautionElemGroup: {
            warning: {
              symbol: "!",
              warningAndCautionPara: step.stepDescription,
            },
          },
        },
        isolationStepQuestion: "",
        isolationStepAnswer: {
          listOfChoices: {
            choice: [
              {
                _nextActionRefId: "stp-1",
                __text: "Yes",
              },
              {
                _nextActionRefId: "stp-2",
                __text: "No",
              },
            ],
          },
        },
        _id: step.stepId, // Ensure correct step numbering
      };
  });

    const data = 
    {
      "s1000d": {
        "dmodule": {
          "dmCode": {
            "_modelIdentCode": "",
            "_systemDiffCode": "",
            "_systemCode": "",
            "_subSystemCode": "",
            "_subSubSystemCode": "",
            "_assyCode": "",
            "_disassyCode": "",
            "_disassyCodeVariant": "",
            "_infoCode": "",
            "_infoCodeVariant": "",
            "_itemLocationCode": ""
          },
          "dmTitle": form.faultTitle,
          "dmStatus": "",
          "content": {
            "descriptive": {
          "description": {
            "hazardSection": {
              "hazard": {
                "hazardIdent": {
                  "hazardId": "H001",
                  "hazardTitle": form.Hazards
                },
                "hazardNote": {
                  "para": "This equipment contains high voltage components which can cause serious injury or death. Always disconnect power before servicing."
                },
                "hazardMitigation": {
                  "para": "Use insulated tools and wear rubber gloves when working on high voltage components. Follow all lockout/tagout procedures."
                }
              }
            }
          }
        },
            "procedure": {
              "faultIsolationProcedure": {
                "fault": {
                  "_faultCode": form.faultCode?form.faultCode:"",
                  "faultDescr": {
                    "descr": form.faultDescription
                  },
                  
                  "isolationProcedure": {
                    "preliminaryRqmts": {
                      "productMaintData": {
                        "workAreaLocationGroup": ""
                      },
                      "reqSupportEquips": {
                        "noSupportEquips": form.SupportEquips?form.SupportEquips:""
                      },
                      "reqSupplies": {
                        "noSupplies": form.Supplies?form.Supplies:""
                      },
                      "reqSpares": {
                        "noSpares": form.Spares?form.Spares:""
                      },
                      "reqSafety": {
                        "noSafety": form.SafetyWarnings?form.SafetyWarnings:""
                      },
                      "reqCondGroup": {
                        "noConds": ""
                      }
                    },
                    "isolationMainProcedure": {
                      "isolationStep":isolationSteps,
                      "isolationProcedureEnd": {
                        "para": "If any issues are found during the inspection, follow the appropriate maintenance procedures to correct them. Ensure all safety and operational checks are performed before returning the turbine to service.",
                        "spares": "",
                        "tools": "",
                        "_id": ""
                      }
                    }                 
                  }
                },
                "faultIsolationStep":{
                  "symptomDescription": {
							"symptom": {
								"symptomId": "S001",
								"symptomTitle": "No Power",
								"symptomDetail": form.Symptoms?form.Symptoms:""
							}
						},
                }
              }
            }
          },
          "refs": ""
        }
      },
      "status":publish?"PUBLISHED":"DRAFT"
    };
   
    
    await saveNewDocument(data).then((response) => {
      setShowPendingAlert(false)
      props.history.push("/")
    });
    // props.resetProcedureDetails();
  };

  // Create formdata to save doc
  const createFormDocumentData = (doc, isEditDoc = false) => {
    const formData = new FormData();

    const uploadedMedia = doc.uploadedMedia;
    let docDetail;
    if (isEditDoc) {
      docDetail = {
        faultTitle: doc.faultTitle,
        faultDescription: doc.faultDescription,
        documentType: doc.documentType,
        status: doc.status,
        category: doc.category,
        mediaToBeDeleted: doc.mediaToBeDeleted,
        editedFields: doc.editedFields,
      };
    } else {
      docDetail = {
        faultTitle: doc.faultTitle,
        faultDescription: doc.faultDescription,
        documentType: doc.documentType,
        status: doc.status,
        category: doc.category,
      };
    }
    formData.append("data", JSON.stringify(docDetail));
    if (uploadedMedia && uploadedMedia.length > 0) {
      for (let i = 0; i < uploadedMedia.length; i++) {
        formData.append("media", uploadedMedia[i]);
      }
    }
    return formData;
  };

  const handleDone = async () => {
    setEnableDonePopup(true);
    
  };
  const getNewMedia = () => {
    let length = props.prevDocData.length - 1;
    const newMediaFileName = mediaFilesDownloaded
      .map((media) => {
        const isUniqueFileName = props.prevDocData[length].media.every(
          (prevMedia) => {
            if (prevMedia.fileName !== media.fileName) {
              return media.fileName;
            }
          }
        );

        if (isUniqueFileName) {
          return media.fileName;
        }

        return null; // or any default value if needed
      })
      .filter(Boolean); // Filter out null values

    return newMediaFileName;
  };

  const updateDocumentData = async (publish) => {
    let isolationSteps;
    isolationSteps = props.stepDetails.map((step,i) => {
      return {
        isolationStepHeadingElemGroup: {
          title: step.stepTitle,
          warningAndCautionElemGroup: {
            warning: {
              symbol: "!",
              warningAndCautionPara: step.stepDescription,
            },
          },
        },
        isolationStepQuestion: "Are all earthing cables properly routed and connected?",
        isolationStepAnswer: {
          listOfChoices: {
            choice: [
              {
                _nextActionRefId: "stp-1",
                __text: "Yes",
              },
              {
                _nextActionRefId: "stp-2",
                __text: "No",
              },
            ],
          },
        },
        _id: step.stepId, // Ensure correct step numbering
      };
  });

    const data = 
    {
      "s1000d": {
        "dmodule": {
          "dmCode": {
            "_modelIdentCode": "",
            "_systemDiffCode": "",
            "_systemCode": "",
            "_subSystemCode": "",
            "_subSubSystemCode": "",
            "_assyCode": "",
            "_disassyCode": "",
            "_disassyCodeVariant": "",
            "_infoCode": "",
            "_infoCodeVariant": "",
            "_itemLocationCode": ""
          },
          "dmTitle": form.faultTitle,
          "dmStatus": "",
          "content": {
            "descriptive": {
          "description": {
            "hazardSection": {
              "hazard": {
                "hazardIdent": {
                  "hazardId": "H001",
                  "hazardTitle": form.Hazards
                },
                "hazardNote": {
                  "para": "This equipment contains high voltage components which can cause serious injury or death. Always disconnect power before servicing."
                },
                "hazardMitigation": {
                  "para": "Use insulated tools and wear rubber gloves when working on high voltage components. Follow all lockout/tagout procedures."
                }
              }
            }
          }
        },
            "procedure": {
              "faultIsolationProcedure": {
                "fault": {
                  "_faultCode": form.faultCode?form.faultCode:"",
                  "faultDescr": {
                    "descr": form.faultDescription
                  },
                  
                  "isolationProcedure": {
                    "preliminaryRqmts": {
                      "productMaintData": {
                        "workAreaLocationGroup": ""
                      },
                      "reqSupportEquips": {
                        "noSupportEquips": form.SupportEquips?form.SupportEquips:""
                      },
                      "reqSupplies": {
                        "noSupplies": form.Supplies?form.Supplies:""
                      },
                      "reqSpares": {
                        "noSpares": form.Spares?form.Spares:""
                      },
                      "reqSafety": {
                        "noSafety": form.SafetyWarnings?form.SafetyWarnings:""
                      },
                      "reqCondGroup": {
                        "noConds": ""
                      }
                    },
                    "isolationMainProcedure": {
                      "isolationStep":isolationSteps,
                      "isolationProcedureEnd": {
                        "para": "If any issues are found during the inspection, follow the appropriate maintenance procedures to correct them. Ensure all safety and operational checks are performed before returning the turbine to service.",
                        "spares": "",
                        "tools": "",
                        "_id": ""
                      }
                    }                 
                  }
                },
                "faultIsolationStep":{
                  "symptomDescription": {
							"symptom": {
								"symptomId": "S001",
								"symptomTitle": "No Power",
								"symptomDetail": form.Symptoms?form.Symptoms:""
							}
						},
                }
              }
            }
          },
          "refs": ""
        }
      },
      "status":publish?"PUBLISHED":"DRAFT"
    };
   
    
    await updateDocument(data, props.procedureDetails.documentId)
      .then((response) => {
        data.documentId = props.procedureDetails.documentId;

        setDcoumentData(data);
        props.setProcedureDetails(response.data.data.documents);

        // if (response.data.data.documents?.media?.length > 0) {
       
        //   setMediaFilesDownloaded(
        //     getMediaFiles(response.data.data.documents.media)
        //   );
        //   props.mediafiletorender(
        //     getMediaFiles(response.data.data.documents.media)
        //   );
        // }
        // //to avoid unwanted API calls during autosave
        // mediaToBeSaved.length > 0 && setMediaToBeSaved([]);
        setDeleteFileCollection([]);
        setLoading(false);
        props.history.push("/")
      })
      .catch((err) => {
        if (err.response.status === 403) {
          KeycloakUserService.doLogout();
        }
      });
  };

  // Create formdata to save step
  const createFormData = (step, isEditstep = false) => {
    const formData = new FormData();
    let stepDetail = {
      stepTitle: step.stepTitle,
      description: step.stepDescription,
      order_id: step.order_id,
      _id: step.stepId,
    };
    formData.append("data", JSON.stringify(stepDetail));
    return formData;
  };

  const handlePreview = (e , publish=false) => {
    e.preventDefault();
    handleDone(publish);
  };

  const disableCreateStep = () => {
    return (
      !props.editStep ||
      props.stepDetails[0]?.stepTitle === defaultStep.stepTitle
    );
  };

  const sortSteps = (steps) => {
    steps.sort(function (a, b) {
      return a.order_id - b.order_id;
    });
  };

  const updateDocumentSteps = async (autosave) => {
   
    //Save steps aganist existing solution , condition is to call the function if step is ordered and it should not be called during autosave since it causes irrelevant api calls.

    !autosave && props.stepOrdered && (await saveStepsByDocument());
  };

  // Get unique steps Ids
  const getUniqueIds = (editStepIds) => {
    return editStepIds.filter(
      (item, index) => editStepIds.indexOf(item) === index
    );
  };

  // Save steps by solution id
  const saveStepsByDocument = async () => {
    sortSteps(props.stepDetails);
    const editedStepIdCollection = editedStepIdsState;
    props.resetEditStepIds(true);
    const editedStepIds = getUniqueIds(editedStepIdCollection);
    for (let i = 0; i < props.stepDetails.length; i++) {
      const step = props.stepDetails[i];
      for (let i = 0; i < editedStepIds.length; i++) {
        const stepId = editedStepIds[i];
        if (step.stepId === stepId) {
          const formData = createFormData(step, true);
          await saveEditedStep(
            props.procedureDetails.documentId,
            formData,
            stepId
          ).catch((err) => {
            if (err.response.status === 403) {
              KeycloakUserService.doLogout();
            }
          });
        }
      }
    }
    setEditedStepIdsState([]);
  };
  const getMediaFiles = (media) => {
    media.forEach((file) => {
      file.extention = file.fileName.split(".").pop();
    });
    setFileDetails([]);
    return media;
  };
  let submitAlertClass = showSaveAlert ? "modal-alert animate" : "modal-alert";
  let submitAlertStyles =
    showSaveAlert || showPendingAlert
      ? {}
      : { opacity: 0, visibility: "hidden", pointerEvents: "none" };

  const renderUploadFile = (e) => {
    if (saveInProgress) {
      setShowToast(true);
    } else {
      e.preventDefault();
      e.stopPropagation();
      fileRef.current.click();
    }
  };

  const renderImageInsertFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    imageRef.current.click();
  };
  const mediaFullScreen = (media) => {
    setMedia(media);
  };
  useEffect(() => {
    mediaToBeSaved.length > 0 && handleDone(false);
    mediaToBeSaved.length === 0 && setLoading(false);
  }, [mediaToBeSaved]);
  const handleVideoUpload = (e) => {
    setLoading(true);

    //when video is uploaded cancel popup need to show
    props.setFormEdit(true);
    setFormContentChange(true);
    handleInputChange(e, "video");
  };
  // Set Uploaded files
  const handleInputChange = async (e, type) => {
    e.preventDefault();
    const fileData = e.target.files;

    if (type === "video") {
      for (let i = 0; i < fileData.length; i++) {
        if (validateFile(fileData[i])) {
          if (!fileData[i].name) {
            const binary = atob(fileData[i].split(",")[1]);
            const array = [];
            var file;
            for (let i = 0; i < binary.length; i += 1) {
              array.push(binary.charCodeAt(i));
            }
            file = new Blob([new Uint8Array(array)], {
              type: "image/png",
            });
            file.name = `${new Date()}.png`;
            file.lastModifiedDate = new Date();
            const myFile = new File([file], file.name, {
              type: file.type,
            });
            setFileDetails((fileDetails) => [...fileDetails, myFile]);
            setMediaToBeSaved((fileDetails) => [...fileDetails, myFile]);
          }
          setFileDetails((fileDetails) => [...fileDetails, fileData[i]]);
          setMediaToBeSaved((fileDetails) => [...fileDetails, fileData[i]]);
        }
      }
    } else {
      const formFile = await getformData(fileData);
      setShowPendingAlert(true);
      await uploadMedia(formFile).then((res) => {
        setUploadedMedia(res.data.data.url);
        props.passMediaURL(res.data.data.url);
        setShowPendingAlert(false);
        setFileUpload(true);
        setShowSaveAlert(true);
        setTimeout(() => {
          setShowSaveAlert(false);
        }, 1000);
      });
    }
  };

  const getformData = async (file) => {
    const formData = new FormData();
    formData.append("media", file[0]);
    return formData;
  };

  const renderImagesAndVideos = () => {
    var rendreFiles = [];
    // Downloaded files
    if (mediaFilesDownloaded) {
      mediaFilesDownloaded.forEach((media) => {
        if (
          media.extention === "png" ||
          media.extention === "PNG" ||
          media.extention === "jpeg" ||
          media.extention === "JPEG" ||
          media.extention === "jpg" ||
          media.extention === "JPG"
        ) {
          rendreFiles.push(
            <div className="image-container">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <img
                    className="photo"
                    src={media.url}
                    typeof="image/png, image/jpeg, image/jpg"
                    alt=""
                    onClick={() => {
                      mediaFullScreen(media);
                    }}
                  />
                }
                position="left center"
              >
                {/* {media.fileName.split("//")[1]} */}
                {media.fileName.substring(media.fileName.lastIndexOf("/") + 1)}
              </Popup>

              <button
                className="close-button-position"
                onClick={() => removeFilePopupHandler(media.fileName, "media")}
              >
                X
              </button>
            </div>
          );
        }
        if (
          media.extention === "mp4" ||
          media.extention === "MP4" ||
          media.extention === "MOV" ||
          media.extention === "mov"
        ) {
          rendreFiles.push(
            <div className="image-container">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <video
                    className="photo"
                    onClick={() => {
                      mediaFullScreen(media);
                    }}
                  >
                    <source className="photo" src={media.url} alt="" />
                  </video>
                }
                position="left center"
              >
                {/* {media.fileName.split("//")[1]} */}
                {media.fileName.substring(media.fileName.lastIndexOf("/") + 1)}
              </Popup>

              <button
                className="close-button-position"
                onClick={() => removeFilePopupHandler(media.fileName, "media")}
              >
                X
              </button>
            </div>
          );
        }
      });
    }

    // Uploaded files
    if (fileDetails?.length > 0) {
      for (let i = 0; i < fileDetails.length; i++) {
        if (
          fileDetails[i].type === "image/png" ||
          fileDetails[i].type === "image/jpeg" ||
          fileDetails[i].type === "image/jpg" ||
          fileDetails[i].type === "image/JPG" ||
          fileDetails[i].type === "image/JPEG" ||
          fileDetails[i].type === "image/PNG"
        ) {
          rendreFiles.push(
            <div className="image-container">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <img
                    src={URL.createObjectURL(fileDetails[i])}
                    className="photo"
                    typeof="image/png, image/jpeg, image/jpg, image/*, JPEG/H.264"
                    onClick={() => {
                      mediaFullScreen(fileDetails[i]);
                    }}
                    alt=""
                  />
                }
                position="left center"
              >
                {fileDetails[i].name}
              </Popup>

              <button
                className="close-button-position"
                onClick={() =>
                  removeFilePopupHandler(fileDetails[i].name, "fileDetails")
                }
              >
                X
              </button>
            </div>
          );
        } else if (
          fileDetails[i].type === "video/mp4" ||
          fileDetails[i].type === "MOV" ||
          fileDetails[i].type === "mov" ||
          fileDetails[i].type === "video/quicktime" ||
          fileDetails[i].type === "video/*" ||
          fileDetails[i].type === "HEVC/H.264" ||
          fileDetails[i].type === "hevc/h.264"
        ) {
          rendreFiles.push(
            <div className="image-container">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <video
                    preload="metadata"
                    className="photo"
                    onClick={() => {
                      mediaFullScreen(fileDetails[i]);
                    }}
                  >
                    <source
                      className="photo"
                      src={URL.createObjectURL(fileDetails[i])}
                      alt=""
                    />
                  </video>
                }
                position="left center"
              >
                {fileDetails[i].name}
              </Popup>

              <button
                className="close-button-position"
                onClick={() =>
                  removeFilePopupHandler(fileDetails[i].name, "fileDetails")
                }
              >
                X
              </button>
            </div>
          );
        }
      }
    }
    return rendreFiles;
  };
  const handledraftAI = () => {

    props.history.push("/draftWithAI")
  }


  const removeFilePopupHandler = (mediaFileName, mediaType) => {
    //([mediaFileName, mediaType]);
  };

  // Remove files
  const removeFile = (fileName, collectionName) => {
    //setFileToBeRemoved([]);
    setLoading(true);
    const fileUpdatedCollection = [];
    switch (collectionName) {
      case "fileDetails":
        if (fileDetails.length > 0) {
          for (let i = 0; i < fileDetails.length; i++) {
            if (fileDetails[i].name !== fileName) {
              fileUpdatedCollection.push(fileDetails[i]);
            }
          }
          setFileDetails(fileUpdatedCollection);
        }
        break;
      case "media":
        if (mediaFilesDownloaded) {
          mediaFilesDownloaded.forEach((media) => {
            if (media.fileName !== fileName) {
              fileUpdatedCollection.push(media);
            } else {
              setDeleteFileCollection((deleteFileCollection) => [
                ...deleteFileCollection,
                media.fileName,
              ]);
            }
          });
          setMediaFilesDownloaded(fileUpdatedCollection);
          props.mediafiletorender(fileUpdatedCollection);
        }
        break;
      default:
        break;
    }
  };

  const getTransitionTime = () => {
    if (fileDetails.length > 0) {
      for (let i = 0; i < fileDetails.length; i++) {
        if (
          fileDetails[i].type === "video/mp4" ||
          fileDetails[i].type === "MOV" ||
          fileDetails[i].type === "mov" ||
          fileDetails[i].type === "video/quicktime" ||
          fileDetails[i].type === "video/*"
        ) {
          return "40s";
        }
        return "3s";
      }
    } else if (fileDetails.length > 5) {
      return "10s";
    }
    return "2s";
  };

  const validateFile = (fileData) => {
    const fileType = fileData.name.split(".").pop().toLowerCase();
    if (fileData.size > 100 * 1024 * 1024) {
      const fileSize = (fileData.size / 1024 / 1024).toFixed(2);
      alert(
        "The attached file (" +
        fileSize +
        ") exceeds the maximum size limit of 100 MB. Please reduce the file size and try again."
      );
      setLoading(false);
      return false;
    } else if (!(fileType === "mov" || fileType === "mp4")) {
      alert(
        "We do not accept files of this media type. Only file with mp4 and mov are allowed."
      );
      setLoading(false);
      return false;
    } else {
      const isFileExist = fileDetails?.some(
        (file) => file.name === fileData.name
      );
      const isFileAlreadySaved = mediaFilesDownloaded?.some(
        (file) => file.fileName.split("/").pop() === fileData.name
      );
      if (isFileExist || isFileAlreadySaved) {
        alert(
          "The file you are trying to upload has the same name as an existing file. Please rename the file or choose a different one."
        );
        setLoading(false);
        return false;
      }
    }
    return true;
  };
  const showChangeLog = () => {
    setShowChangeLog((changeLog) => !changeLog);
  };

  return (
    <div style={{ display: changeLog ? "flex" : "" }}>
      <div className="session-summary form-page-wrapper">
      
       

        
        {enableDonePopup && (
          <Modal
            className="modal-upload-delete"
            open={enableDonePopup}
            onClose={() => {
              setEnableDonePopup(false);
            
            }}
          >
            <div className="modal-heading-delete">
              <div>
                <h3>Do you want to publish?</h3>
                <p></p>
              </div>

              <button
                style={{ marginRight: "1rem" }}
                onClick={(e) => {
                  setEnableDonePopup(false)
                }}
              >
                X
              </button>
            </div>
            <div className="modal-action-delete">
              <Button
                className="modal-button-option-del1"
                onClick={(e) => handleNo(e)}
              >
                No
              </Button>
              <Button
                className="modal-button-option-del2"
                onClick={(e) => handleYes()}
              >
                Yes
              </Button>
            </div>
          </Modal>
        )}
        
        {showPendingAlert && (
          <div
            className={submitAlertClass}
            style={submitAlertStyles}
            variant={"warning"}
          >
            <div className="modal-alert-icon">
              <img
                className="PopUpIcon"
                src={require("../../assets/images/pending-shape.png")}
                alt=""
              />
            </div>
            <div className="model-alert-msg">
              <h4 className="Heading">
                {fileUpload
                  ? "File upload in progress"
                  : "Document saving in progress"}
              </h4>
              <p className="Para">
                {fileUpload
                  ? "Please wait until file upload completed"
                  : "Please wait while saving is completed"}
              </p>
              <ProgressBar
                completed={90}
                bgColor="#08bd5c"
                labelColor="#1e1818"
                transitionDuration={getTransitionTime()}
                transitionTimingFunction="ease-in"
                animateOnRender
                customLabel=" "
                height=""
              />
            </div>
          </div>
        )}
        
      
    
        <Form>
          <div style={{ width: "98%" }}>
          <div style={{display:"flex" , justifyContent:"flex-end"}}>
             
           {props.aiSolnCreated ?  <Button
            className="cancelbtn regenerate"
                  onClick={()=> handledraftAI()}
           
          >
          <img className="regenerate-icon" src={regenerate}/>  Regenerate
          </Button> :    <Popup 
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                position="top left"
                trigger={ 
                <Button 
                className={`(form.category !== "" && form.procedureTitle !== "") ? "ai-button" : "ai-button-disable" `} 
                onClick={()=> handledraftAI()}>
                
                <div className="ai-button">
                 
                <svg viewBox="0 0 24 24" role="presentation" class="css-1s7n6uk" >
                  <defs>
                    <linearGradient id="ai_icon_lg0" x1="21" y1="12" x2="17.5" y2="12" gradientUnits="userSpaceOnUse"><stop offset="0.270833" stop-color="var(--ds-icon-accent-blue, #2684FF)" color="var(--ds-icon-accent-blue, #2684FF)"></stop><stop offset="1" stop-color="var(--ds-text-accent-blue, #0052CC)" color="var(--ds-text-accent-blue, #0052CC)"></stop></linearGradient><linearGradient id="ai_icon_lg1" x1="12.5" y1="3" x2="12.5" y2="6.5" gradientUnits="userSpaceOnUse"><stop offset="0.1875" stop-color="var(--ds-icon-accent-blue, #2684FF)" color="var(--ds-icon-accent-blue, #2684FF)"></stop><stop offset="1" stop-color="var(--ds-text-accent-blue, #0052CC)" color="var(--ds-text-accent-blue, #0052CC)"></stop></linearGradient><linearGradient id="ai_icon_lg2" x1="6.82996" y1="12" x2="3.32996" y2="12" gradientUnits="userSpaceOnUse"><stop stop-color="var(--ds-text-accent-blue, #0052CC)" color="var(--ds-text-accent-blue, #0052CC)"></stop><stop offset="0.791667" stop-color="var(--ds-icon-accent-blue, #2684FF)" color="var(--ds-icon-accent-blue, #2684FF)"></stop></linearGradient><linearGradient id="ai_icon_lg3" x1="12.5" y1="17" x2="12.5" y2="21" gradientUnits="userSpaceOnUse"><stop stop-color="var(--ds-text-accent-blue, #0052CC)" color="var(--ds-text-accent-blue, #0052CC)"></stop><stop offset="0.802083" stop-color="var(--ds-icon-accent-blue, #2684FF)" color="var(--ds-icon-accent-blue, #2684FF)"></stop></linearGradient>
                  </defs>
                  <g>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.17 12C17.17 11.4477 17.6177 11 18.17 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H18.17C17.6177 13 17.17 12.5523 17.17 12Z" fill="url(#ai_icon_lg0)"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C12.5523 3 13 3.44772 13 4V5.83C13 6.38228 12.5523 6.83 12 6.83C11.4477 6.83 11 6.38228 11 5.83V4C11 3.44772 11.4477 3 12 3Z" fill="url(#ai_icon_lg1)"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M3 12C3 11.4477 3.44772 11 4 11H5.83C6.38228 11 6.83 11.4477 6.83 12C6.83 12.5523 6.38228 13 5.83 13H4C3.44772 13 3 12.5523 3 12Z" fill="url(#ai_icon_lg2)"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M12 17.17C12.5523 17.17 13 17.6177 13 18.17V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V18.17C11 17.6177 11.4477 17.17 12 17.17Z" fill="url(#ai_icon_lg3)"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2929 14.2929C14.6835 13.9024 15.3166 13.9024 15.7071 14.2929L19.7071 18.2929C20.0977 18.6834 20.0977 19.3166 19.7071 19.7071C19.3166 20.0976 18.6835 20.0976 18.2929 19.7071L14.2929 15.7071C13.9024 15.3166 13.9024 14.6834 14.2929 14.2929Z" fill="var(--ds-icon-accent-blue, #2684FF)"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M19.7071 4.29289C20.0977 4.68342 20.0977 5.31658 19.7071 5.70711L15.7071 9.70711C15.3166 10.0976 14.6835 10.0976 14.2929 9.70711C13.9024 9.31658 13.9024 8.68342 14.2929 8.29289L18.2929 4.29289C18.6835 3.90237 19.3166 3.90237 19.7071 4.29289Z" fill="var(--ds-icon-accent-blue, #2684FF)"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L9.70711 8.29289C10.0976 8.68342 10.0976 9.31658 9.70711 9.70711C9.31658 10.0976 8.68342 10.0976 8.29289 9.70711L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z" fill="var(--ds-icon-accent-blue, #2684FF)"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M9.70711 14.2929C10.0976 14.6834 10.0976 15.3166 9.70711 15.7071L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L8.29289 14.2929C8.68342 13.9024 9.31658 13.9024 9.70711 14.2929Z" fill="var(--ds-icon-accent-blue, #2684FF)"></path>
                  </g>
                  </svg>  
                   <span className="icon-AI">AI</span></div></Button>}
                >Draft with AI</Popup>}

          </div>

            <h3 style={{ backgroundColor: "#F5F5F5" }}>
            Create a Troubleshooting Guide
            </h3>
          </div>{" "}
          <Form.Field required className="input-wrap">
            <p className="form-label">Fault Title* </p>
            <input
              name="faultTitle"
              placeholder="Name the fault title"
              className="instruction-input"
              value={form.faultTitle}
              onChange={handleChange}
              maxLength="250"
            />

            {form.faultTitle === "" && props.editForm && (
              <span style={{ color: "red" }}>
                Fault Title is a mandatory field.
              </span>
            )}
          </Form.Field>
        
          <Form.Field required className="input-wrap">
            <div className="desc-wrapup">
            <p className="form-label" style={{ paddingBottom: "8px" }}>Fault Description*</p>
           
          </div>
            <FroalaEditor
              content={form.faultDescription}
              getContent={getContent}
            />

            {description === "" && props.editForm && (
              <span style={{ color: "red" }}>
                Fault Description is a mandatory field.
              </span>
            )}
          </Form.Field>        
        </Form>
        <Form >
        <Form.Field required className="input-wrap cards-xtdoc-container" >
        <div>
          <p className="form-label"> Fault Code</p>
          <input maxLength="250" className="instruction-input" name="faultCode"     value={form.faultCode}
              onChange={handleChange}/> 
          </div>
          <div>
            <p className="form-label">Symptoms:</p>
          <input maxLength="250" className="instruction-input"  name="Symptoms" value={form.Symptoms}
              onChange={handleChange} />
              </div>
          <div>
            <p className="form-label">Support Equipments:</p>
          <input maxLength="250" className="instruction-input"  name="SupportEquips" value={form.SupportEquips}
              onChange={handleChange} />
              </div>
          <div>
            <p className="form-label">Hazards:</p>
          <input maxLength="250" className="instruction-input"  name="Hazards" value={form.Hazards}
              onChange={handleChange} />
              </div>
          <div>
            <p className="form-label">Tools and Parts:</p>
          <input maxLength="250" className="instruction-input"    name="Spares" value={form.Spares}
              onChange={handleChange} />
              </div>
          <div>
            <p className="form-label">Safety Warnings:</p>
          <input maxLength="250" className="instruction-input"  name="SafetyWarnings" value={form.SafetyWarnings}
              onChange={handleChange} />
              </div>
          </Form.Field>
          </Form>
        <div className="input-wrap">
          <p className="form-label">Steps of Troubleshooting Guide</p>
          <div className="stepbtn-ctn" >
    {!props.stepDetails.length ?<p className="form-label" >Add Steps here</p>:<p className="form-label" ></p>}  
              <Button className="stepbtn" disabled={createStep} href="" onClick={(e) => handleCreateStep(e)}>
                +
              </Button>     
            </div>
        <div   >
         
           {props.stepDetails.map((step)=> 
            <CreateInstructionsSteps createStep={false} step={step} setCreateStep={setCreateStep}/>)}
             
          </div>
          <div ref={createStepRef}>
        {createStep && <CreateInstructionsSteps createStep={createStep} step={[]} setCreateStep={setCreateStep}/> }  
        </div>
        </div>

        <div className="buttons" >
          <Button
            className="savebtn"
            disabled={form.faultTitle==="" || form.faultDescription===""||!props.stepDetails.length}
            onClick={() => handleDone()}
          >
            Done
          </Button>

         
          <Button
            className="cancelbtn"
            onClick={(e) => handlecancelStep(e)}
            disabled={showPendingAlert || showSaveAlert}
          >
            Cancel
          </Button>
        </div>
      </div>
    

      {/* {props?.procedureDetails?.lastApprovedVersion &&  (
        <ChangeLog
          visible={changeLog}
          setShowChangeLog={setShowChangeLog}
          form={form}
          steps={false}
        />
      )} */}
    </div>
  );
};
//editStep & stepEdit are two different redux store , editstep is used to enable editing the step while stepEdit is to check if editing happened in step
const mapStateToProps = (state) => {
  return {
    stepDetails: state.stepDetails,
    template: state.template,
    editStep: state.editStep,
    procedureDetails: state.procedureDetails,
    editedStepIds: state.editedStepIds,
    isReviewer: state.isReviewer,
    isNewDocument: state.isNewDocument,
    isFromFeedback: state.isFromFeedback,
    feedback: state.feedback,
    feedbackData: state.feedbackData,
    docMedia: state.docMedia,
    enablePreviewButton: state.enablePreviewButton,
    editForm: state.editForm,
    stepOrdered: state.stepOrdered,
    stepEdit: state.stepEdit,
    showPopupRedux: state.showPopupRedux,
    mediarender: state.mediarender,
    blankField: state.blankField,
    prevDocData: state.prevDocData,
    featureFlag: state.featureFlag,
    aiSolnCreated:state.aiSolnCreated
  };
};

export default connect(mapStateToProps, {
  addStep,
  setSelectedTemplate,
  setEnableEditStep,
  setProcedureDetails,
  resetProcedureDetails,
  saveDocumentMedia,
  setPreview,
  resetEditStepIds,
  saveEditedStepIds,
  resetEditStep,
  saveIsReviewer,
  setNewDocument,
  saveDocumentfeedback,
  resetDocumentMedia,
  resetFeedback,
  setEnablePreview,
  setFormEdit,
  passMediaURL,
  setBlankFields,
  showPopupAction,
  mediafiletorender,
  savePrevData,
  setStepFormEdit,
})(CreateInstructionsForm);