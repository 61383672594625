import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import Header from "./menu/Header";
import Notifications from "./menu/Notifications";
import LexxDashboard from "./LexxDashboard/LexxDashboard";
import CreateInstructionsForm from "./LexxInstructions/CreateInstructionsForm";
import CreateInstructionsSteps from './LexxInstructions/CreateInstructionsSteps';
import { loginAction, saveActiveTime, setFeatureflag} from "../redux/actions/index";
import Feedbacktable from "./Lexxtableforfeedback/Feedbacktable";
import LexxIdlePopup from "../components/LexxIdlePopup/LexxIdlePopup";
import Preview from "./LexxInstructions/Preview";
import PreviewReadonly from "./LexxInstructions/PreviewReadonly";
import IdleTimer from "react-idle-timer";
import { logData} from "./apiCall";
import KeycloakUserService from "../components/login/KeyCloakService";
import DraftwithAI from "./LexxInstructions/DraftWithAI";

const App = (props) => {

  const [showModal, setShowModal] = useState(false);
  const timeout = 1000 * 60 * 30; // 30mins
  const [timer, setTimer] = useState(null);
  const [isTimedOut, setIsTimeOut] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const onActive = () => {
    setIsTimeOut(false);
  };

  useEffect(() => {
    // getFeatureFlag().then((res) => {
    //   let featureObj = {};
    //   for (let i = 0; i < res.data[0].features.length; i++) {
    //     featureObj[res.data[0].features[i].featureId] =
    //       res.data[0].features[i].state === "enabled" ? true : false;
    //   }
    //   props.setFeatureflag(featureObj);
    // });

    const handleKeyDown = (event) => {
      // Check if the user presses Ctrl+P (key code 80) on Windows/Linux or Command+P (key code 80) on macOS
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 80) {
        event.preventDefault(); // Prevent the default Ctrl+P behavior (print)
        handlePrint(); // Call your custom print function here
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };


  }, []);

  const handlePrint = () => {
    // Custom print logic here
    setIsPrinting(true);
    window.print();
    setIsPrinting(false);
  };

  const onIdle = (e) => {
    if (!isTimedOut) {
      setShowModal(true);
      setTimer(null);
      setIsTimeOut(true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleLogout = () => {    
    props.history.push("/");
    const data = {
      params: {
        username: props.userInfo.username,
        sessionTime: timer.getElapsedTime() / 1000,
      },
    };
    logData(data);
    setShowModal(false);
    KeycloakUserService.doLogout();
  };

  const onAction = (e) => {
    setIsTimeOut(false);
  };

  if (!KeycloakUserService.isLoggedIn()){
    return KeycloakUserService.doLogin();
  } else {
  return (
    <div>
      <IdleTimer
        ref={(ref) => {
          setTimer(ref);
          props.saveActiveTime(ref == null ? null : ref.getElapsedTime());
        }}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        timeout={timeout}
      />
      {!isPrinting? (<Header />):(<div></div>)}
        
          <Switch>
            <Route exact path="/" component={LexxDashboard} />
            <Route exact path="/allNotifications/" component={Notifications} />
            <Route exact path="/CreateInstructionsForm" component={CreateInstructionsForm}/>
            <Route exact path="/CreateInstructionsSteps" component={CreateInstructionsSteps}/>
            <Route exact path="/Feedbacklist" component={Feedbacktable}/>
            <Route exact path="/Preview" component={Preview}/>
            <Route exact path="/ReadonlyPreview" component={PreviewReadonly}/>
            <Route exact path="/draftWithAI" component={DraftwithAI}/>
            <Route exact path="*" render={() => <Redirect to="/" />} />
          </Switch>
      {showModal ? (
        <LexxIdlePopup
          showModal={showModal}
          handleClose={handleClose}
          handleLogout={handleLogout}
        />
      ) : null}
    </div>
  );
};
}

const mapStateToProps = (state) => {
  return {
    sidebarVisible: state.sidebarVisible,
    userInfo: state.userInfo,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loginAction,
    saveActiveTime,
    setFeatureflag
  })(App)
);
