import React, { useCallback, useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import { ClientRoles } from "../../const/ClientRoles";
import KeycloakUserService from "../login/KeyCloakService";
import DocumentList from "./DocumentList";
import LexxDashboardCard from "./LexxDashboardCard";
import {
  saveIsReviewer,
  setFormEdit,
  setStepOrdered,
  setStepEdit,
  passMediaURL,
  mediafiletorender,
  removeNewStepIds,
  setStepFormEdit,
  showPopupAction,
  resetPrevData,saveDaftWithAIForm,aiSolnCreatedAction
} from "./../../redux/actions/index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SummaryOfDoc from "./SummaryOfDoc";

/* This is the component to display the Dashboard (main page after login) */
const LexxDashboard = (props) => {
  /* This component calls 2 components 
        1. Dashboard Card - for buttons
        2. Carousel - for session and task carousel
    */
  const [userRoles, setUserRoles] = useState(
    KeycloakUserService.getClientRoles()
  );
  const  [statusOptionSelectedSummmary , setStatusOptionSelectedSummmary] = useState("");

  const [feedbackDocCount, setFeedbackDocCount] = useState(0);
  const [awaitingReviewDocCount, setAwaitingReviewDocCount] = useState(0);
  const [inProgressDocCount, setInProgressDocCount] = useState(0);
  const [approvedDocCount, setApprovedDocCount] = useState(0);
  const [draftCount,setDraftCount] = useState(0);
  const [statusFromDropDown , setStatusFromDropDown] = useState("");
  const setCount = (data)=>{
  
    setApprovedDocCount(data.approvedCount);
        setAwaitingReviewDocCount(data.reviewCount);
        setInProgressDocCount(data.inProgressDocCount);
        setFeedbackDocCount(data.feedbackDocCount);
        setDraftCount(data.draftCount);
  }

  useEffect(()=>{
props.saveDaftWithAIForm({});
props.aiSolnCreatedAction(false)
props.setFormEdit(false)

  },[])
const setStatusOptionSelectedSummmaryFunc = (data)=>{
  setStatusOptionSelectedSummmary(data)
}

  return (
    <>
      {/* <SidebarComp /> */}
      <div className="content-container home-container">
        <Container className="dashboard-container">
          <h2>Dashboard</h2>
          <hr style={{ opacity: "50%" }} />
          <LexxDashboardCard />
          {/* <SummaryOfDoc setStatusOptionSelectedSummmary={setStatusOptionSelectedSummmaryFunc} feedbackDocCount={feedbackDocCount} awaitingReviewDocCount={awaitingReviewDocCount} inProgressDocCount={inProgressDocCount} approvedDocCount={approvedDocCount} draftCount={draftCount} statusFromDropDown={statusFromDropDown} /> */}
          <DocumentList  statusOptionSelectedSummmary={statusOptionSelectedSummmary} setCount={setCount} setStatusFromDropDown={setStatusFromDropDown} />
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isReviewer: state.isReviewer,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    saveIsReviewer,
    setFormEdit,
    setStepOrdered,
    setStepEdit,
    passMediaURL,
    mediafiletorender,
    removeNewStepIds,
    setStepFormEdit,
    showPopupAction,
    resetPrevData,saveDaftWithAIForm,aiSolnCreatedAction
  })(LexxDashboard)
);
