import React, { useState, useEffect } from "react";
import {
  Table,
  Label,
  Input,
  Form,
  Dropdown,
  Grid,
  Tab,
  Modal,
  Button,
  Popup,
  Icon,
} from "semantic-ui-react";
import { colorName } from "./colorname";
import {
  getAllDocument,
  deleteDocument,
  getDocumentById,
  getStepsByDocumentId,
} from "../apiCall";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  setDocumentCollection,
  setProcedureDetails,
  setSelectedTemplate,
  updateDefaultStep,
  addStep,
  saveIsReviewer,
  setIsFromFeedback,
  setDocumentId,
  resetDocumentMedia,
  mediafiletorender,
  savePrevData,addStepFromApi
} from "./../../redux/actions/index";
import KeycloakUserService from "../login/KeyCloakService";

const DocumentList = (props) => {
  const [documents, setDocuments] = useState([]);
  const [documentsByStatus, setDocumentsByStatus] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [dataId, setDataId] = useState("");
  const userEmail = KeycloakUserService.getEmail();
  const [menuSelected, setMenuOption] = useState("My Documents");
  const [documentFiltered, setDocumentFiltered] = useState([]);
  const [sorting, setSorting] = useState({
    key: "modifiedOn",
    ascending: false,
  });
 const [ docArrayPopulated , setDocArrayPopulated] = useState(false);
  const [statusOptionSelected, setStatusOptionSelected] = useState('all');

  const getIconEnable = (data) => {
    return (
      !props.isReviewer && data.status.toUpperCase() === "AWAITING_REVIEW"
      // ||
      // ((data.status.toUpperCase() === "IN_PROGRESS" ||
      //   data.status.toUpperCase() === "DRAFT") &&
      //   data.modifiedBy !== userEmail)
    );
  };

  const feedbackIconStatus = (data) => {
    return (
      <div style={{ position: "relative" }}>
        <i
          className={
            data?.status?.toUpperCase() === "APPROVED" ||
            data?.feedbackComments?.length
              ? "comment icon action-icon"
              : "comment icon disabled"
          }
          id="penIcon"
          onClick={
            data?.status.toUpperCase() === "APPROVED" ||
            data?.feedbackComments?.length > 0
              ? () => handleonClickFeedback(data)
              : null
          }
        >
          {data?.feedbackComments?.length > 0 && (
            <span className="feedback-counter">
              <strong>{data?.feedbackComments?.length}</strong>
            </span>
          )}
        </i>
      </div>
    );
  };

  const handleonClickFeedback = (data) => {
    props.setIsFromFeedback(true);
    props.history.push("/Feedbacklist");
    props.setDocumentId(data.documentId);
  };
useEffect(()=>{
  props.statusOptionSelectedSummmary && handleStatusChange(null,props.statusOptionSelectedSummmary)
},[props.statusOptionSelectedSummmary])

  const handleDeleteOnclick = (data) => {
    setDataId(data.documentId);
    setPopupVisible(true);
  };
  const deleteIconStatus = (data) => {
    return (
      <i
        className={
          getIconEnable(data)
            ? "trash alternate icon disabled"
            : "trash alternate icon action-icon"
        }
        id="penIcon"
        onClick={getIconEnable(data) ? null : () => handleDeleteOnclick(data)}
      ></i>
    );
  };
  const editIconStatus = (data) => {
    return (
      <i
        className={
          getIconEnable(data)
            ? "pencil alternate icon disabled"
            : "pencil alternate icon action-icon"
        }
        id="penIcon"
        onClick={getIconEnable(data) ? null : () => handleEditSolution(data)}
      >
        {" "}
      </i>
    );
  };
  const panes = [
    // {
    //   menuItem: "All Documents",
    //   render: () => displayTab("All Documents"),
    // },
    {
      menuItem: "My Documents",
      render: () => displayTab("My Documents"),
    },
  ];
  const renderTabContent = () => {
    return (
      <Tab
        menu={{ color: "blue", secondary: true, pointing: true }}
        panes={panes}
      />
    );
  };

  

  useEffect(() => {
    sortDocuments(sorting.key, sorting.ascending);
    // updateSummary(documentsByStatus)
  }, [documentsByStatus]);

  const sortDocuments = (key, ascending) => {
    const documentsCopy = documentsByStatus;

    const sortedDocuments = documentsCopy.sort((a, b) => {
      return a[key].localeCompare(b[key]);
    });
    setDocumentsByStatus(
      ascending ? sortedDocuments : sortedDocuments.reverse()
    );
  };

  const applySorting = (key, ascending) => {
    setSorting({ key: key, ascending: ascending });
    sortDocuments(key, ascending);
  };

  // display my document and all document
  const displayTab = (menuOption) => {
    setMenuOption(menuOption);
    return (
      <div className="tableWrap">
        <Table
          celled
          unstackable
          style={{ border: "none" }}
          className="table-wrapper"
        >
          <Table.Header>
            <Table.Row style={{ position: "sticky", top: "0", zIndex: "1" }}>
              <Table.HeaderCell
                onClick={() =>
                  applySorting("procedureTitle", !sorting.ascending)
                }
              >
                Document Name
                {sorting.ascending && sorting.key === "procedureTitle" ? (
                  <Icon name="sort up" />
                ) : (
                  <Icon name="sort down" />
                )}
              </Table.HeaderCell>
              <Table.HeaderCell
                className="table-header"
                onClick={() => applySorting("createdBy", !sorting.ascending)}
              >
                Created By
                {sorting.ascending && sorting.key === "createdBy" ? (
                  <Icon name="sort up" />
                ) : (
                  <Icon name="sort down" />
                )}
              </Table.HeaderCell>
              <Table.HeaderCell
                className="table-header"
                onClick={() => applySorting("createdOn", !sorting.ascending)}
              >
                Created On
                {sorting.ascending && sorting.key === "createdOn" ? (
                  <Icon name="sort up" />
                ) : (
                  <Icon name="sort down" />
                )}
              </Table.HeaderCell>
              <Table.HeaderCell
                className="table-header"
                onClick={() => applySorting("modifiedBy", !sorting.ascending)}
              >
                Last Modified By
                {sorting.ascending && sorting.key === "modifiedBy" ? (
                  <Icon name="sort up" />
                ) : (
                  <Icon name="sort down" />
                )}
              </Table.HeaderCell>
              <Table.HeaderCell
                className="table-header"
                onClick={() => applySorting("modifiedOn", !sorting.ascending)}
              >
                Last Modified on
                {sorting.ascending && sorting.key === "modifiedOn" ? (
                  <Icon name="sort up" />
                ) : (
                  <Icon name="sort down" />
                )}
              </Table.HeaderCell>
              <Table.HeaderCell
                className="table-header"
                onClick={() => applySorting("status", !sorting.ascending)}
              >
                Status
                {sorting.ascending && sorting.key === "status" ? (
                  <Icon name="sort up" />
                ) : (
                  <Icon name="sort down" />
                )}
              </Table.HeaderCell>
              <Table.HeaderCell className="table-header">
                Actions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{displayData(menuOption)}</Table.Body>
          {popupVisible && (
            <Modal
              className="modal-upload-delete"
              open={popupVisible}
              onClose={() => setPopupVisible(false)}
            >
              <div className="modal-heading-delete">
                <div>
                  <h3>Confirm Delete</h3>
                  <p>Are you sure you want to delete the document?</p>
                </div>

                <button
                  style={{ marginRight: "1rem" }}
                  onClick={() => setPopupVisible(false)}
                >
                  X
                </button>
              </div>
              <div className="modal-action-delete">
                <Button
                  className="modal-button-option-del1"
                  onClick={() => setPopupVisible(false)}
                >
                  No
                </Button>
                <Button
                  className="modal-button-option-del2"
                  onClick={() => handleDelete()}
                >
                  Yes
                </Button>
              </div>
            </Modal>
          )}
        </Table>
      </div>
    );
  };

  /* This functions gets all the solutions from the db */
  const getDocuments = () => {
    getAllDocument()
      .then((response) => {
      
        setDocArrayPopulated(true)
        const documentCollection =response.data.data.documents;
        props.setDocumentCollection(documentCollection);
        setDocuments(documentCollection);
        setDocumentsByStatus(documentCollection);
        setDocumentFiltered(documentCollection);
      
      })
      .catch((error) => {
        // KeycloakUserService.doLogout();
      });
  };

  useEffect(() => {
  
    getDocuments();
  
  }, []);

  const handleDelete = async () => {
    await deleteDocument(dataId);
    getDocuments();
    setPopupVisible(false);
    setDataId("");
  };

  const handleEditSolution = async (data) => {
    await getStoreData(true, data);
  };

  const getStoreData = async (isEdit, data) => {
    await getDocumentById(data.documentId).then((response) => {

      // props.setSelectedTemplate(response.data.data.document.documentType);

      // response.data.data.document.media.forEach((file) => {
      //   if (file.fileName) {
      //     const ext = file.fileName.split(".").pop();
      //     file.extention = ext;
      //   }
      // });
  
      const data = {
        documentId:response?.data?.data?.document?.documentId,
        faultTitle: response?.data?.data?.document?.s1000d?.dmodule?.dmTitle,
        faultCode: response?.data?.data?.document?.s1000d?.dmodule?.content?.procedure?.faultIsolationProcedure?.fault?._faultCode,
        faultDescription: response?.data?.data?.document?.s1000d?.dmodule?.content?.procedure?.faultIsolationProcedure?.fault?.faultDescr?.descr,
        Symptoms:response?.data?.data?.document?.s1000d?.dmodule?.content?.procedure?.faultIsolationProcedure?.faultIsolationStep?.symptomDescription?.symptom?.symptomDetail,
        SupportEquips:response?.data?.data?.document?.s1000d?.dmodule?.content?.procedure?.faultIsolationProcedure?.fault?.isolationProcedure?.preliminaryRqmts?.reqSupportEquips?.noSupportEquips,
        SafetyWarnings:response?.data?.data?.document?.s1000d?.dmodule?.content?.procedure?.faultIsolationProcedure?.fault?.isolationProcedure?.preliminaryRqmts?.reqSafety?.noSafety,      
        Spares:response?.data?.data?.document?.s1000d?.dmodule?.content?.procedure?.faultIsolationProcedure?.fault?.isolationProcedure?.preliminaryRqmts?.reqSpares?.noSpares,
        Hazards:response?.data?.data?.document?.s1000d?.dmodule?.content?.descriptive?.description?.hazardSection?.hazard?.hazardIdent?.hazardTitle
      };
     
    let isolationSteps = response?.data.data.document.s1000d.dmodule.content.procedure.faultIsolationProcedure.fault.isolationProcedure.isolationMainProcedure.isolationStep.map((steps)=>{
      return {
        stepId:steps._id,
        stepTitle:steps.isolationStepHeadingElemGroup.title ,
        stepDescription:steps.isolationStepHeadingElemGroup.warningAndCautionElemGroup.warning.warningAndCautionPara

      }
    })
      props.addStepFromApi(isolationSteps)
      props.setProcedureDetails(data);
      props.savePrevData(data);
      props.mediafiletorender(
        response.data.data.document.media
          ? response.data.data.document.media
          : []
      );
      props.history.push(`/CreateInstructionsForm`);
    });

  
  };

  const handleDocumentClick = (document) => {
    getStoreData(false, document);
  };

  const getDocumentTitle = (document) => {
    let docTitle = document.s1000d.dmodule.dmTitle;
   
    return (
      <Popup
        style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
        trigger={
          <a
            style={{ textDecoration: "underline" }}
            onClick={() => handleDocumentClick(document)}
          >
            {docTitle}
          </a>
        }
        position="left center"
      >
        {docTitle}
      </Popup>
    );
  };
  const redirectToOutlook = (email) => {
    window.open(
      `https://outlook.office365.com/mail/deeplink/compose?to=${email}`,
      "_blank"
    );
  };
  const displayData = (menuOption) => {
   
    return documentsByStatus.length  ? documentsByStatus
      ?.filter((info) => {
        return (
          ((menuOption === "My Documents" &&
            (info.modifiedBy === userEmail || info.createdBy === userEmail)) ||
          menuOption === "All Documents")&& (true)
        );
      })
      .map((info) => {
        return (
          info.s1000d.dmodule.dmTitle.length > 0 && (
            <Table.Row key={info.documentId}>
              <Table.Cell className="table-content">
                {getDocumentTitle(info)}
              </Table.Cell>
              <Table.Cell className="table-header">{info.createdBy}</Table.Cell>
              <Table.Cell className="table-header">
                {moment(info.createdOn).format("MM/DD/YYYY")}
              </Table.Cell>
              <Table.Cell
                className="table-header"
                onClick={() => {
                  redirectToOutlook(info.modifiedBy);
                }}
              >
                <a>{info.modifiedBy}</a>
              </Table.Cell>
              <Table.Cell className="table-header">
                {moment(info.modifiedOn).format("MM/DD/YYYY")}
              </Table.Cell>
              <Table.Cell className={colorName(info.status)}>
                <Label horizontal>
                  {info.status.toUpperCase().replace("_", " ")}
                </Label>
              </Table.Cell>
              <Table.Cell className="align-center">
                <Popup
                  style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                  trigger={feedbackIconStatus(info)}
                  position="top center"
                >
                  Feedback
                </Popup>
                <Popup
                  style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                  trigger={editIconStatus(info)}
                  position="top center"
                >
                  Edit Document
                </Popup>
                <Popup
                  style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                  trigger={deleteIconStatus(info)}
                  position="top center"
                >
                  Delete Document
                </Popup>
              </Table.Cell>
            </Table.Row>
          )
        );
      }):<div className="centered-content">
      <div className="No-data">{docArrayPopulated ? <h2>No Documents</h2>:<h2>Fetching data...</h2>}</div></div>;
  };

  const handleStatusChange = (event, data) => {
  

    setStatusOptionSelected(data.value);
    props.setStatusFromDropDown(data);
    let statusKey = "";
  
      if (data.value.toLowerCase() === statusOptions[0].value.toLowerCase()) {
        setDocumentsByStatus(documents);
        setDocumentFiltered(documents);
      } else {
        if (data.value.toLowerCase() === statusOptions[1].value.toLowerCase()) {
          statusKey = statusOptions[1].key;
        }
        if (data.value.toLowerCase() === statusOptions[2].value.toLowerCase()) {
          statusKey = statusOptions[2].key;
        }
        
      

        let filteredArray = documents.filter(function (el) {
          return el.status.toLowerCase() == statusKey.toLowerCase();
        });

        setDocumentFiltered(filteredArray);
        setDocumentsByStatus(filteredArray);
      }
    
  };

  const statusOptions =  [
    {
      key: "all",
      text: "ALL",
      value: "all",
    },
    {
      key: "PUBLISHED",
      text: "PUBLISHED",
      value: "published",
    },
    {
      key:"DRAFT",
      text: "DRAFT",
      value:"draft",
    }
  ]

  const onFilter = (key) => {

    var filterdata = documentFiltered
      .filter((x) => x.s1000d.dmodule.dmTitle.toLowerCase().includes(key.toLowerCase()))
      .filter((x) => x !== undefined);
   
    setDocumentsByStatus(filterdata);
  };

  return (
    <div className="session-summary documentlist document-table">
      <div className="search-dropdown">
        <Form>
    
          <Grid padded>
            <Grid.Row stretched>
              <Grid.Column className="searchsolution" width={13}>
                <Form.Field>
                  <Popup
                    style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                    trigger={
                      <Input
                        icon="search"
                        iconPosition="left"
                        placeholder={
                          menuSelected === "My Documents"
                            ? "Search My Documents"
                            : "Search All Documents"
                        }
                        onChange={(e) => {
                          onFilter(e.target.value);
                        }}
                      />
                    }
                    position="top left"
                  >
                    Search by document name
                  </Popup>
                </Form.Field>
              </Grid.Column>
              <Grid.Column className="searchsolutionstatus">
                <Dropdown
                  className="statusdropdown"
                  selection
                  value={statusOptionSelected}
                  options={statusOptions}
                  defaultValue={statusOptionSelected}
                  onChange={handleStatusChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
      <div className="main-header">{renderTabContent()}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    documentList: state.documentList,
    isReviewer: state.isReviewer,
    isFromFeedback: state.isFromFeedback,
    documentId: state.documentId,
    prevDocData: state.prevDocData,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setDocumentCollection,
    setProcedureDetails,
    setSelectedTemplate,
    addStep,
    updateDefaultStep,
    saveIsReviewer,
    addStepFromApi,
    setIsFromFeedback,
    setDocumentId,
    resetDocumentMedia,
    mediafiletorender,
    savePrevData,
  })(DocumentList)
);
