import React, { useCallback, useEffect, useState } from 'react'
import {Form ,Button, Accordion, AccordionContent, Popup, Icon, Modal} from "semantic-ui-react";
import FroalaEditor from './FriolaEditor/FroalaEditor';
import {
  addStep,
  setSelectedTemplate,
  setEnableEditStep,
  setProcedureDetails,
  resetProcedureDetails,
  saveDocumentMedia,
  setPreview,
  resetEditStepIds,
  resetEditStep,
  saveIsReviewer,
  setNewDocument,
  resetDocumentMedia,
  resetFeedback,
  saveDocumentfeedback,
  setEnablePreview,
  setFormEdit,
  passMediaURL,
  setBlankFields,
  showPopupAction,
  mediafiletorender,
  saveEditedStepIds,
  savePrevData,
  setStepFormEdit,updateStep,setStepDetails,
  addStepFromApi
} from "./../../redux/actions";
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
const CreateInstructionsSteps = (props) => {

  const defaultStep= {stepTitle:"",stepDescription:""}

  const  [formVal , setformVal] =  useState(props.step.length?props.step:defaultStep);
  const [description, setDescription] = useState(formVal.stepDescription);
  const  [formChange , setFormChange] = useState(false)
  const [activeIndex, setActiveIndex] = useState(1)
  const [edit , setEdit] =  useState(false)
  const [dropDown, setDropDown] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }
  const handleChange= (e)=>{
    setFormChange(true)
    setformVal({...formVal ,[e.target.name]:e.target.value })
  }

  useEffect(()=>{
    if(description?.length || formChange){
      setFormChange(true)
    setformVal({...formVal ,"stepDescription":description })
  }

},[description])
useEffect(()=>{
  if(props.createStep){
    setActiveIndex(0);
    setEdit(true)
  }
},[])
useEffect(()=>setformVal(props.step),[props.step])
 

  const addStepFunc= () =>{
    if(formVal.stepTitle && formVal.stepDescription && formChange)
      {
       
    props.setStepDetails({})
    props.setCreateStep(false)
    props.addStep({stepId:uuidv4(),stepTitle:formVal.stepTitle,stepDescription:formVal.stepDescription})
      }
    else{
      setFormChange(true)
      alert("Enter All Mandatory Fields for Steps")
    }
  }

  const editStepFunc= () =>{
    if(formVal.stepTitle && formVal.stepDescription && formChange){
      
      props.setStepDetails({})
      props.setCreateStep(false)
      
      props.updateStep({stepId:formVal.stepId,stepTitle:formVal.stepTitle,stepDescription:formVal.stepDescription})
    } else{
      setFormChange(true)
      alert("Enter All Mandatory Fields for Steps")
    }
    setEdit(false)
   
  }
  const cancelFun=()=>{  props.setStepDetails({});
  props.setCreateStep(false)
  setformVal(props.step)
  setEdit(false)}
  const handleEdit=(e)=>{
    setActiveIndex(0)
    e.stopPropagation()
    setEdit(true)

  }
  // handle delete step
  const handleDelete = async (e) => {
    e.stopPropagation();
    const selectedStep = props.stepDetails.filter((step) => step.stepId !== props.step.stepId);
   
    props.setStepDetails(selectedStep)
    // delete step
    props.addStepFromApi(selectedStep);
    setPopupVisible(false);
  };
  const onClickSection = () => {
    props.step.stepId !== "001" && setDropDown(!dropDown);
    let sectionIndex = 1;
    let newActiveIndex = activeIndex === sectionIndex ? -1 : sectionIndex;
    props.step.stepId !== "001" && setActiveIndex(newActiveIndex);
  };
  const getContent = useCallback((descriptionContent) => {
    setDescription(descriptionContent);
  }, []);
  const handleDeleteButton = (e)=>{
    e.stopPropagation();
       setPopupVisible(true);
   }
  return (
    <div >
     
      <div>
      <Accordion fluid styled inverted>
        <Accordion.Title
         active={activeIndex === 0}
         index={0}
         onClick={handleClick}
        >
{props.step.stepTitle}
<div className="wrap-icons">
                  <Popup
                    style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                    trigger={
                      <div
                        className="expand"
                        onClick={(e) => {
                           handleEdit(e);
                        }}
                      >
                        <Icon
                          name="pencil alternate"
                          index={1}
                          className={
                             "dropdown-icon action-icon"
                          }
                          
                        />
                      </div>
                    }
                    position="top center"
                  >
                    Edit Step
                  </Popup>
                  <Popup
                    style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                    trigger={
                      <div
                        className="expand"
                        onClick={(e) => handleDeleteButton(e)}
                      >
                        <Icon
                          name="trash"
                          index={1}
                          className={
                            "dropdown-icon action-icon"
                          }
                         
                        />
                      </div>
                    }
                    position="top center"
                  >
                    Delete Step
                  </Popup>
                  <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <div className="expand" onClick={onClickSection}>
                    <Icon
                      name="chevron down"
                      index={1}
                      className={"dropdown-icon action-icon"}
                      style={{
                        transform:
                           dropDown && "rotate(180deg)",
                        fontSize: "1.2em",
                      }}
                      disabled={props.step.stepId === "001"}
                    />
                  </div>
                }
                position="top center"
              >
                {activeIndex === 0 || activeIndex === -1
                  ? "Expand Step"
                  : "Collapse Step"}
              </Popup>
              
              </div>
        </Accordion.Title>
        <AccordionContent active={activeIndex === 0}>
        <Form>
       
    <Form.Field required className="input-wrap">
            <p className="form-label-AI">Step Title<span style={{color:"red"}}>*</span> </p>
            <input
              name="stepTitle"
             readOnly={!edit}
              placeholder="Step Title"
              className="draft-input"
              value={formVal.stepTitle}
              onChange={handleChange}
              maxLength="250"
            />
             {formVal.stepTitle==="" && formChange && (
              < span style={{ color: "red" }}>
                Step Title is a mandatory field.
              </span>
            )}
          </Form.Field>
          <Form.Field required className="input-wrap">
            <p className="form-label-AI">Description<span style={{color:"red"}}>*</span> </p>
            {!edit?
               <p style={{ fontSize: "14px" }}>
               <div className='step-desc'  dangerouslySetInnerHTML={{__html: formVal.stepDescription}}  />
             </p>:<FroalaEditor
              content={formVal.stepDescription}
              getContent={getContent}/>}
                 { description === "" &&  formChange && (
              <span style={{ color: "red" }}>
                Step Description is a mandatory field.
              </span>
            )}
          </Form.Field>
         
          </Form>
      {edit &&    <div className="buttons">
          <Button
            className="savebtn"
            onClick={()=>props.createStep?addStepFunc():editStepFunc()}
          >
        
          { props.createStep?"Add Step":"Edit Step"}
          </Button>
          <Button
            className="cancelbtn"
            onClick={()=>cancelFun()}
          >
            Cancel
          </Button>
        </div>}
        </AccordionContent>
      </Accordion>
      </div>
    
        <Modal
          className="modal-upload-delete"
          open={popupVisible}
          onClose={() => setPopupVisible(false)}
        >
          <div className="modal-heading-delete">
            <div>
              <h3>Confirm Delete</h3>
              <p>Are you sure you want to delete the step?</p>
            </div>
            <button
              onClick={() => setPopupVisible(false)}
              style={{ marginRight: "1rem" }}
            >
              X
            </button>
          </div>
          <div className="modal-action-delete">
            <Button
              className="modal-button-option-del1"
              onClick={() => setPopupVisible(false)}
            >
              No
            </Button>
            <Button
              className="modal-button-option-del2"
              onClick={(e) => handleDelete(e)}
            >
              Yes
            </Button>
          </div>
        </Modal>
      
    </div>
  )
}
//editStep & stepEdit are two different redux store , editstep is used to enable editing the step while stepEdit is to check if editing happened in step
const mapStateToProps = (state) => {
  return {
    stepDetails: state.stepDetails,
    stepData: state.stepData,
    editStep: state.editStep,
    procedureDetails: state.procedureDetails,
    editedStepIds: state.editedStepIds,
    isReviewer: state.isReviewer,
    isNewDocument: state.isNewDocument,
    isFromFeedback: state.isFromFeedback,
    feedback: state.feedback,
    feedbackData: state.feedbackData,
    docMedia: state.docMedia,
    enablePreviewButton: state.enablePreviewButton,
    editForm: state.editForm,
    stepOrdered: state.stepOrdered,
    stepEdit: state.stepEdit,
    showPopupRedux: state.showPopupRedux,
    mediarender: state.mediarender,
    blankField: state.blankField,
    prevDocData: state.prevDocData,
    featureFlag: state.featureFlag,
  };
};
export default connect(mapStateToProps, {
  addStep,
  setSelectedTemplate,
  setEnableEditStep,
  setProcedureDetails,
  resetProcedureDetails,
  saveDocumentMedia,
  setPreview,
  resetEditStepIds,
  saveEditedStepIds,
  resetEditStep,
  saveIsReviewer,
  setNewDocument,
  saveDocumentfeedback,
  resetDocumentMedia,
  resetFeedback,
  setEnablePreview,
  setFormEdit,
  passMediaURL,
  setBlankFields,
  showPopupAction,
  mediafiletorender,
  savePrevData,
  setStepFormEdit,updateStep,setStepDetails,addStepFromApi
})(CreateInstructionsSteps);
