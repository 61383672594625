import axios from "axios";

/* Save the active time spent on application */
export const saveActiveTime = (time) => {
  return {
    type: "ACTIVE_TIME",
    payload: time,
  };
};

/* This is the login action where the token from server is stored */
export const loginAction = (username, token) => {
  // Set the default parameters for all axios calls
  localStorage.setItem("app_token", `Bearer ${token}`);
  axios.defaults.params = {};
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return {
    type: "LOGIN",
    payload: username,
  };
};

/* Save the geolocation of user */
export const geolocation = (loc) => {
  return {
    type: "LOCATION",
    payload: loc,
  };
};

export const addStep = (step) => {
  return {
    type: "ADD_STEP",
    payload: step,
  };
};

export const addStepFromApi = (step) => {
  return {
    type: "ADD_STEP_FROM_API",
    payload: step,
  };
};
export const updateDefaultStep = (step) => {
  return {
    type: "UPDATE_DEFAULT_STEP",
    payload: step,
  };
};

export const setSelectedTemplate = (template) => {
  return {
    type: "SET_SELECTED_TEMPLATE",
    payload: template,
  };
};

export const setEnableEditStep = (enable) => {
  return {
    type: "SET_ENABLE_EDIT",
    payload: enable,
  };
};

export const editStepDetail = (stepDetail) => {
  return {
    type: "EDIT_STEP_DATA",
    payload: stepDetail,
  };
};

export const resetEditStep = (reset) => {
  return {
    type: "RESET_EDIT_STEP",
    payload: reset,
  };
};

export const saveEditedStepIds = (id) => {
  return {
    type: "SAVE_EDITED_STEP_ID",
    payload: id,
  };
};
export const setEnablePreview = (enable) => {
  return {
    type: "SET_ENABLE_PREVIEW",
    payload: enable,
  };
};
export const setFormEdit = (edited) => {
  return {
    type: "SET_FORM_EDITED",
    payload: edited,
  };
};
export const setStepFormEdit = (edited) => {
  return {
    type: "SET_STEP_FORM_EDITED",
    payload: edited,
  };
};
export const setProcedureDetails = (procedureDetails) => {
  return {
    type: "SAVE_PROCEDURE_DATA",
    payload: procedureDetails,
  };
};

export const setDocumentCollection = (documentList) => {
  return {
    type: "SAVE_DOCUMENT_LIST",
    payload: documentList,
  };
};

export const resetProcedureDetails = () => {
  return {
    type: "RESET_PROCEDURE_DATA",
    payload: true,
  };
};

export const resetStepDetails = () => {
  return {
    type: "RESET_STEPDETAILS",
    payload: true,
  };
};

/*Remove step from step collection */
export const removeStep = (step) => {
  return {
    type: "REMOVE_STEP",
    payload: step,
  };
};

export const updateStep = (step) => {
  return {
    type: "UPDATE_STEP",
    payload: step,
  };
};

export const setPreview = (isPreview) => {
  return {
    type: "SET_PREVIEW",
    payload: isPreview,
  };
};

/** Rest Edit Step IDs collection */
export const resetEditStepIds = (reset) => {
  return {
    type: "RESET_EDIT_STEPIDS",
    payload: reset,
  };
};

export const saveIsReviewer = (isReviewer) => {
  return {
    type: "SAVE_IS_REVIEWER",
    payload: isReviewer,
  };
};

export const setNewDocument = (isNewDocument) => {
  return {
    type: "IS_NEW_DOCUMENT",
    payload: isNewDocument,
  };
};

export const setIsFromFeedback = (isFromFeedback) => {
  return {
    type: "IS_FROM_FEEDBACK",
    payload: isFromFeedback,
  };
};

export const setFeedback = (feedback) => {
  return {
    type: "IS_NEW_FEEDBACK",
    payload: feedback,
  };
};
export const setStepOrdered = (ordered) => {
  return {
    type: "IS_STEP_ORDERED",
    payload: ordered,
  };
};
export const setStepEdit = (edit) => {
  return {
    type: "IS_STEP_EDITED",
    payload: edit,
  };
};

export const setDocumentId = (documentId) => {
  return {
    type: "IS_NEW_DOCUMENTID",
    payload: documentId,
  };
};

export const saveDocumentfeedback = (feedbackData) => {
  return {
    type: "FEEDBACK_DATA",
    payload: feedbackData,
  };
};

export const saveDocumentMedia = (fileDetails) => {
  return {
    type: "SAVE_DOCUMENT_MEDIA",
    payload: fileDetails,
  };
};

export const resetDocumentMedia = (reset) => {
  return {
    type: "RESET_DOCUMENT_MEDIA",
    payload: reset,
  };
};

export const setStepDetails = (stepData) => {
  return {
    type: "SET_STEP_DATA",
    payload: stepData,
  };
};

export const setStepMediaFiles = (media) => {
  return {
    type: "SET_STEP_MEDIA",
    payload: media,
  };
};

export const setUnReadNotification = (count) => {
  return {
    type: "NOTIFICATION_COUNT",
    payload: count,
  };
};

export const clearFeedbackData = (clear) => {
  return {
    type: "CLEAR_FEEDBACKDATA",
    payload: clear,
  };
};

export const setFeatureflag = (featureflag) => {
  return {
      type: "SET_FEATURE_FLAG",
      payload: featureflag
  }
};

export const viewFeedback = (value) => {
  return {
    type: "VIEW_FEEDBACK",
    payload: value,
  };
};

export const resetFeedback = (value) => {
  return {
    type: "RESET_FEEDBACK",
    payload: value,
  };
};
export const passMediaURL = (value) => {
  return {
    type: "MEDIA_ADDED",
    payload: value,
  };
};

export const saveDocTitle = (value) => {
  return {
    type: "SAVE_DOC_TITLE",
    payload: value,
  };
};
export const setBlankFields = (value) => {
  return {
    type: "BLANK_FIELDS",
    payload: value,
  };
};
export const showPopupAction = (value) => {
  return {
    type: "SHOW_POPUP",
    payload: value,
  };
};

export const mediafiletorender = (value) => {
  return {
    type: "MEDIA_TO_RENDER",
    payload: value,
  };
};

export const savePrevData = (value) => {
  return {
    type: "SAVE_PREV_DATA",
    payload: value,
  };
};
export const resetPrevData = () => {
  return {
    type: "RESET_PREV_DATA",
    payload: [],
  };
};
export const saveNewStepIds = (value) => {
  return {
    type: "SAVE_NEW_STEP_ID",
    payload: value,
  };
};
export const removeNewStepIds = (value) => {
  return {
    type: "REMOVE_NEW_STEP_ID",
    payload: value,
  };
};
export const aiSolnCreatedAction = (value) => {
  return {
    type: "AI_SOLN_CREATED",
    payload: value,
  };
};
export const saveDaftWithAIForm = (value) => {
  return {
    type: "DRAFT_WITH_AI",
    payload: value,
  };
};